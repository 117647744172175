<template>
	<div class="home">
		<div class="home__navbar">
			<Navbar />
		</div>
		<div class="home__header" :style="{ 'background-color': primaryColor }">
			<h1 class="home__header-title">{{ $t('router.home') }}</h1>
		</div>
		<div class="home__body" :style="{ 'background-image': 'url(' + image + ')' }">
			<div class="home__body-sections">
				<Sections :primaryColor="primaryColor" :user="user" />
			</div>
		</div>
		<footer class="home__footer">
			<Footer />
		</footer>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import Sections from '@/components/Home/Sections.vue';
import Footer from '@/components/footer/Footer.vue';
import { getDocument, saveLogsAccess, receiveDateNow } from '@/api/common.js';
import { insertAudit } from '@/api/user.js';

export default {
	name: 'Home',
	title() {
		return `${this.$t('router.home')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		Sections,
		Footer
	},
	data() {
		return {
			image: '',
			colorStyle: {
				'--primaryColor': this.primaryColor
			},
			user: null,
			eventname: ''
		};
	},
	mounted() {
		this.getImage();
		this.checkLoginData();
		this.getEventName();
		const user = JSON.parse(localStorage.getItem('pui9_session'));

		insertAudit({
			usr: user ? user.usr : 'anonymous',
			client: 'CATALOG_' + this.eventname + '_HOME',
			ip: user ? user.lastLoginIp : '0.0.0.0'
		});

		saveLogsAccess({
			loacfecha: receiveDateNow(),
			loacusr: user ? user.usr : 'anonymous',
			loacfuncionalidad: "Principal",
			loaceventgroupid: this.$store.state.eventInfo.eveneventgroupid,
			loacurl: this.$route.path,
			loacip: user ? user.lastLoginIp : '0.0.0.0',
			loacenvironment: "CATÁLOGO",
			loacproductserviceid: 0,
			loacexhibitorid: 0
		});
	},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	methods: {
		getEventName: function () {
			const aux = window.location.pathname.split('/');
			this.eventname = aux[2];
		},
		async getImage() {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_catalog_button_exhibitor');
			this.image = data[0].url;
		},

		checkLoginData() {
			if (localStorage.getItem('pui9_session') !== null) {
				this.user = JSON.parse(localStorage.getItem('pui9_session'));
				console.log(this.user);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.home {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__header,
	&__footer {
		flex: none;
	}
	&__body {
		overflow-y: hidden;
		flex: auto;
	}
}

.home__header {
	background-color: #565567;
	height: 15vh;
	display: flex;
	align-items: center;
}

.home__header-title {
	font-family: Cabin;
	font-weight: bold;
	font-size: 2rem;
	letter-spacing: 0.0625rem;
	color: #fff;
	padding-left: 10%;
	padding-top: 1.5rem;
}

.home__body {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.home__body-sections {
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 10%;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
</style>
