<template>
	<div class="sections">
		<ul>
			<!-- Expositores (siempre salen) -->

			<li :style="hoverStyle">
				<router-link :to="{ name: 'Exhibitors' }" class="sections__link">
					<div class="sections__link-container">{{ $t('menu.catalogexhibitor') }}</div>
				</router-link>
			</li>
			<!-- Productos -->
			<li :style="hoverStyle" v-if="showProduct">
				<router-link :to="{ name: 'Products' }" class="sections__link">
					<div class="sections__link-container">{{ $t('menu.productmarketplace') }}</div>
				</router-link>
			</li>
			<!-- Actividades -->
			<li :style="hoverStyle" v-if="showActivity">
				<router-link :to="{ name: 'Activities' }" class="sections__link">
					<div class="sections__link-container">{{ $t('menu.activities') }}</div>
				</router-link>
			</li>

			<div class="sections__separator"></div>
			<!-- Cuenta/Login -->
			<li :style="hoverStyle">
				<router-link v-if="user !== null" :to="{ name: 'MyAccount' }" class="sections__link">
					<div class="sections__link-container">{{ $t('menu.account') }}</div>
				</router-link>
				<router-link v-else :to="{ name: 'Login' }" class="sections__link">
					<div class="sections__link-container">{{ $t('menu.account') }}</div>
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Sections',
	props: {
		primaryColor: {
			type: String,
			default: '#565567'
		},
		user: {}
	},
	data() {
		return {
			hoverStyle: {
				'--hoverColor': this.primaryColor
			}
		};
	},

	computed: {
		showProduct() {
			return this.$store.state.eventInfo.evencatalogfilterproduct === 1 ? true : false;
		},
		showActivity() {
			return this.$store.state.eventInfo.evencatalogfilteractivity === 1 ? true : false;
		}
	}
};
</script>

<style lang="scss" scoped>
.sections {
	background: #fff;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	padding: 0.5rem;
}

.sections > ul {
	list-style-type: none;
	padding: 0;
	width: 100%;
}

.sections > ul > li > {
	padding: 1rem 1.75rem;
	padding-right: 5.75rem;
}

.sections > ul > li:hover {
	background-color: var(--hoverColor);
	border-radius: 8px;
}

.sections > ul > li:hover > .sections__link {
	color: #fff;
}

.sections__link {
	text-decoration: none;
	font-family: Cabin;
	font-weight: bold;
	font-size: 1rem;
	color: #565567;

	&-container {
		padding: 1rem 1.75rem;
		padding-right: 5.75rem;
	}
}

.sections__separator {
	border-bottom: 1px solid #e3e7e8;
}
</style>
