import Pui9HttpRequests from 'pui9-requests';

export async function getVisitorInfo(email) {
	const controller = `/uservisitor/get?usr=${email}`;
	let data = [];

	await Pui9HttpRequests.getRequest(
		controller,
		null,
		(response) => {
			if (response && response.data) {
				data = response.data;
			}
		},
		(error) => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}

export async function updateVisitorInfo(body) {
	const controller = `/uservisitor/update`;
	let data = [];
	await Pui9HttpRequests.putRequest(
		controller,
		body,
		(response) => {
			if (response && response.data) {
				data = response.data;
				data.responseType = 'success';
			}
		},
		(error) => {
			if (error) {
				data = error;
				data.responseType = 'error';
			}
		}
	);

	return data;
}

export async function insertAudit(pk) {
	const controller = `/puiuser/insertAudit`;
	let data = [];

	await Pui9HttpRequests.patchRequest(
		controller,
		{},
		(response) => {
			if (response && response.data) {
				data = response.data;
			}
		},
		(error) => {
			if (error) {
				data = error;
			}
		},
		pk
	);

	return data;
}
